import firebase from 'firebase/app';
import { AdminConfirmMspRequest, AdminCreateBroadcastRequest } from 'vacctrack';

export default class FunctionsService {
  static instance = new FunctionsService();

  protected async call<T extends object, R extends object = object>(
    name: string,
    data?: T,
    timeout?: number,
  ) {
    return (
      await firebase.functions().httpsCallable(name, { timeout })(data ?? {})
    ).data as R;
  }

  adminConfirmMsp(data: AdminConfirmMspRequest) {
    return this.call<AdminConfirmMspRequest>('adminConfirmMsp', data);
  }

  adminCreateBroadcast(data: AdminCreateBroadcastRequest) {
    return this.call<AdminCreateBroadcastRequest>('adminCreateBroadcast', data);
  }
}
