import { CircularProgress } from '@material-ui/core';
import firebase from 'firebase/app';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import Login from './Login';
import Main from './Main';

const Routes = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState<firebase.User | null | undefined>(undefined);

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(async (u) => {
      if (!u) {
        setUser(u);
        return;
      }

      const { isAdmin } = (await u.getIdTokenResult(true)).claims;

      if (!isAdmin) {
        setUser(null);
        await firebase.auth().signOut();
        window.alert(t('auth.errorMissingPrivileges'));
        return;
      }

      setUser(u);
    });
  }, [t]);

  if (user === undefined) {
    return (
      <CircularProgress
        size={60}
        style={{
          position: 'fixed',
          top: 'calc(50vh - 30px)',
          left: 'calc(50vw - 30px)',
        }}
      />
    );
  }

  return (
    <BrowserRouter>
      <Switch>
        {user ? (
          <Route path="/" component={Main} />
        ) : (
          <Route path="/" component={Login} exact />
        )}
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
