import { OutlinedInput } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FunctionsService from '../../services/FunctionsService';
import VTForm from '../ui/VTForm';

interface Props {
  onSuccess: () => void;
}

const CreateForm = ({ onSuccess }: Props) => {
  const { t } = useTranslation();

  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);

    try {
      await FunctionsService.instance.adminCreateBroadcast({
        title,
        body,
        url,
      });

      setTitle('');
      setBody('');
      setUrl('');

      window.alert(t('shared.success'));

      onSuccess();
    } catch (e) {
      window.alert(t('error.generic'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <VTForm
      title={t('broadcasts.create')}
      onSubmit={onSubmit}
      submitText={t('action.create')}
      loading={loading}
    >
      <OutlinedInput
        placeholder={t('shared.title')}
        value={title}
        required
        margin="dense"
        onChange={(e) => setTitle(e.currentTarget.value)}
      />
      <OutlinedInput
        placeholder={t('broadcasts.body')}
        value={body}
        required
        margin="dense"
        onChange={(e) => setBody(e.currentTarget.value)}
      />
      <OutlinedInput
        inputMode="url"
        placeholder={t('shared.url')}
        value={url}
        margin="dense"
        onChange={(e) => setUrl(e.currentTarget.value)}
      />
    </VTForm>
  );
};

export default CreateForm;
