import {
  AppBar,
  Box,
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import firebase from 'firebase/app';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router';
import Broadcasts from './Broadcasts';
import Certificates from './Certificates';
import Msps from './Msps';
import Patients from './Patients';
import Products from './Products';
import VTContainer from './ui/VTContainer';
import VaccWatch from './VaccWatch';

const Main = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));

  const options = [
    {
      value: '/certificates',
      title: t('nav.certificates'),
    },
    {
      value: '/patients',
      title: t('nav.patients'),
    },
    {
      value: '/vaccwatch',
      title: t('nav.vaccWatch'),
    },
    {
      value: '/msps',
      title: t('nav.msps'),
    },
    {
      value: '/products',
      title: t('nav.products'),
    },
    {
      value: '/broadcasts',
      title: t('nav.broadcasts'),
    },
  ];

  const handleNavigation = (e: React.ChangeEvent<{ value: string }>) => {
    history.push(e.target.value);
  };

  const logout = () => {
    firebase.auth().signOut();
  };

  return (
    <>
      <AppBar position="relative">
        <Toolbar>
          {isSm && (
            <Box sx={{ marginRight: 3 }}>
              <Typography variant="h6" component="h1">
                VaccTrack Admin
              </Typography>
            </Box>
          )}
          <Box component={Paper} sx={{ width: 200, marginRight: 'auto' }}>
            <FormControl variant="outlined" fullWidth>
              <Select
                margin="dense"
                value={location.pathname}
                onChange={handleNavigation}
              >
                {options.map((o) => (
                  <MenuItem key={o.value} value={o.value}>
                    {o.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Button color="inherit" onClick={logout}>
            {t('auth.signOut')}
          </Button>
        </Toolbar>
      </AppBar>
      <VTContainer>
        <Switch>
          <Route component={Certificates} path="/certificates" />
          <Route component={Patients} path="/patients" />
          <Route component={VaccWatch} path="/vaccwatch" />
          <Route component={Msps} path="/msps" />
          <Route component={Products} path="/products" />
          <Route component={Broadcasts} path="/broadcasts" />
          <Redirect to="/certificates" />
        </Switch>
      </VTContainer>
    </>
  );
};

export default Main;
