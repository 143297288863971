import firebase from 'firebase/app';
import { Certificate } from '../../../types';
import { PAGE_SIZE } from '../util/constants';
import { typeConverter } from './FirestoreService';

export interface CertificatesQuery {
  createdFrom: string;
  createdTo: string;
}

export default class CertificatesService {
  static instance = new CertificatesService();

  collection() {
    return firebase
      .firestore()
      .collectionGroup('certificates')
      .withConverter(typeConverter<Certificate>());
  }

  listCertificates(
    { createdFrom, createdTo }: CertificatesQuery,
    startAfter?: firebase.firestore.DocumentSnapshot,
  ) {
    let query = this.collection().orderBy('created', 'desc').limit(PAGE_SIZE);

    if (createdFrom) {
      query = query.where('created', '>=', new Date(createdFrom).getTime());
    }

    if (createdTo) {
      query = query.where('created', '<', new Date(createdTo).getTime());
    }

    if (startAfter) {
      query = query.startAfter(startAfter);
    }

    return query.get();
  }
}
