import { OutlinedInput } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FunctionsService from '../../services/FunctionsService';
import VTForm from '../ui/VTForm';

interface Props {
  onSuccess: () => void;
}

const ManualVerifyForm = ({ onSuccess }: Props) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [npi, setNpi] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);

    try {
      await FunctionsService.instance.adminConfirmMsp({
        email,
        npi,
      });

      setEmail('');
      setNpi('');

      window.alert(t('shared.success'));

      onSuccess();
    } catch (e) {
      window.alert(
        t('msps.error', { context: e.code, defaultValue: t('error.generic') }),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <VTForm
      title={t('msps.confirmManually')}
      onSubmit={onSubmit}
      submitText={t('msps.confirm')}
      loading={loading}
      row
    >
      <OutlinedInput
        type="email"
        placeholder={t('msps.userEmail')}
        value={email}
        required
        margin="dense"
        onChange={(e) => setEmail(e.currentTarget.value)}
      />
      <OutlinedInput
        placeholder={t('msps.npi')}
        value={npi}
        required
        margin="dense"
        onChange={(e) => setNpi(e.currentTarget.value)}
      />
    </VTForm>
  );
};

export default ManualVerifyForm;
