import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PatientSearchQuery } from '../../services/UsersService';
import VTForm from '../ui/VTForm';

interface Props {
  onSearch: (query: PatientSearchQuery) => void;
  loading?: boolean;
}

const PatientSearchForm = ({ onSearch, loading }: Props) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [certificateCount, setCertificateCount] = useState('0');

  return (
    <VTForm
      onSubmit={() =>
        onSearch({ email, firstName, lastName, certificateCount })
      }
      submitText={t('shared.search')}
      loading={loading}
      row
    >
      <TextField
        type="email"
        label={t('shared.email')}
        value={email}
        onChange={(e) => setEmail(e.currentTarget.value)}
      />
      <TextField
        label={t('shared.firstName')}
        value={firstName}
        onChange={(e) => setFirstName(e.currentTarget.value)}
      />
      <TextField
        label={t('shared.lastName')}
        value={lastName}
        onChange={(e) => setLastName(e.currentTarget.value)}
      />
      <TextField
        type="number"
        label={t('patient.certificateCountMin')}
        value={certificateCount}
        inputProps={{
          min: 0,
        }}
        onChange={(e) => setCertificateCount(e.currentTarget.value)}
      />
    </VTForm>
  );
};

export default PatientSearchForm;
