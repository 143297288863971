import { createMuiTheme } from '@material-ui/core/styles';

const SPACING = 8;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#007FDD',
    },
    secondary: {
      main: '#f2b416',
    },
  },
  spacing: SPACING,
});

export default createMuiTheme({
  ...theme,
  components: {},
});
