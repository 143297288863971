import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import React, { Suspense } from 'react';
import theme from '../util/theme';
import Routes from './Routes';

function App() {
  return (
    <>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={<div />}>
          <Routes />
        </Suspense>
      </MuiThemeProvider>
    </>
  );
}

export default App;
