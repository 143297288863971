import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from 'vacctrack';
import { Id } from '../../services/FirestoreService';
import ProductsService from '../../services/ProductsService';
import VTForm from '../ui/VTForm';

interface Props {
  initialValue?: Product & Id;
  onSuccess: () => void;
  onCancel: () => void;
}

const EMPTY_PRODUCT: Product = {
  name: '',
  manufacturer: '',
  description: '',
  brand: '',
  numberOfShots: 1,
  category: 'vaccine',
};

const UpsertForm = ({ initialValue, onSuccess, onCancel }: Props) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<Product>(initialValue ?? EMPTY_PRODUCT);
  const [loading, setLoading] = useState(false);

  if (initialValue) {
    console.log(initialValue.id);
  }

  const onSubmit = async () => {
    setLoading(true);

    try {
      if (initialValue) {
        await ProductsService.instance
          .doc(initialValue.id)
          .set({ id: initialValue.id, ...value });
      } else {
        await ProductsService.instance.create(value);
      }

      setValue(EMPTY_PRODUCT);

      window.alert(t('shared.success'));

      onSuccess();
    } catch (e) {
      window.alert(t('error.generic'));
    } finally {
      setLoading(false);
    }
  };

  const update = (v: Partial<Product>) => {
    setValue((val) => ({
      ...val,
      ...v,
    }));
  };

  return (
    <VTForm
      title={t('products.addNew')}
      onSubmit={onSubmit}
      onCancel={onCancel}
      submitText={t('products.save')}
      loading={loading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            label={t('products.name')}
            value={value.name}
            required
            margin="dense"
            onChange={(e) => update({ name: e.currentTarget.value })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            label={t('products.numberOfShots')}
            value={Number.isNaN(value.numberOfShots) ? '' : value.numberOfShots}
            type="number"
            required
            margin="dense"
            inputProps={{
              min: 1,
              max: 10,
            }}
            onChange={(e) => {
              let v = parseInt(e.currentTarget.value, 10);

              if (!Number.isNaN(v)) {
                if (v > 10) {
                  v = 10;
                }
                if (v < 1) {
                  v = 1;
                }
              }

              update({ numberOfShots: v });
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            label={t('products.brand')}
            value={value.brand}
            required
            margin="dense"
            onChange={(e) => update({ brand: e.currentTarget.value })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            label={t('products.manufacturer')}
            value={value.manufacturer}
            required
            margin="dense"
            onChange={(e) => update({ manufacturer: e.currentTarget.value })}
            fullWidth
          />
        </Grid>
      </Grid>
      <TextField
        variant="outlined"
        label={t('products.description')}
        value={value.description}
        margin="dense"
        onChange={(e) => update({ description: e.currentTarget.value })}
        fullWidth
      />
      <FormControlLabel
        label={t('products.isTest')}
        control={
          <Checkbox
            checked={!!value.isTest}
            onChange={(_, checked) => update({ isTest: checked })}
          />
        }
      />
      <FormControlLabel
        label={t('products.hidden')}
        control={
          <Checkbox
            checked={value.hidden}
            onChange={(_, checked) => update({ hidden: checked })}
          />
        }
      />
    </VTForm>
  );
};

export default UpsertForm;
