import { ColDef } from '@material-ui/data-grid';
import firebase from 'firebase';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useData from '../../hooks/useData';
import CertificatesService, { CertificatesQuery } from '../../services/CertificatesService';
import { DEFAULT_COL_DEF } from '../../util/constants';
import i18n from '../../util/i18n';
import VTDataGrid from '../ui/VTDataGrid';
import CertificatesFilterForm from './CertificatesFilterForm';

const Certificates = () => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<CertificatesQuery>({
    createdFrom: '',
    createdTo: '',
  });

  const cb = useCallback(
    async (startAfter?: firebase.firestore.DocumentSnapshot) =>
      (
        await CertificatesService.instance.listCertificates(
          searchQuery,
          startAfter,
        )
      ).docs,
    [searchQuery],
  );

  const { data, loading, reload, ...rest } = useData(cb);

  const columns: ColDef[] = [
    {
      ...DEFAULT_COL_DEF,
      field: 'createdFmt',
      headerName: t('shared.created'),
    },
    {
      ...DEFAULT_COL_DEF,
      field: 'serviceDateFmt',
      headerName: t('certificates.serviceDate'),
    },
    {
      ...DEFAULT_COL_DEF,
      field: 'nameFmt',
      headerName: t('shared.name'),
      width: 260,
    },
    {
      ...DEFAULT_COL_DEF,
      field: 'productFmt',
      headerName: t('products.title'),
      width: 480,
    },
  ];

  const certificates = data.map((sn) => sn.data()!);

  const rows = certificates.map((d) => ({
    createdFmt: i18n.dateTimeFormat(d.created),
    serviceDateFmt: i18n.dateTimeFormat(d.serviceDate),
    nameFmt:
      ((d.patient as unknown) as { name: string }).name ||
      [d.patient.firstName, d.patient.middleName, d.patient.lastName]
        .filter(Boolean)
        .join(' '),
    productFmt: [
      d.product.name,
      d.product.brand,
      d.product.manufacturer,
      ...(!d.isTest && d.shotNumber && d.shotNumberOf
        ? [`${d.shotNumber}/${d.shotNumberOf}`]
        : []),
    ]
      .filter(Boolean)
      .join(', '),
    ...d,
  }));

  return (
    <>
      <CertificatesFilterForm
        {...{ onSearch: (s) => setSearchQuery(s), loading }}
      />
      <VTDataGrid
        rows={rows}
        columns={columns}
        onCellClick={(u) => console.log(u.row)}
        {...rest}
      />
    </>
  );
};

export default Certificates;
