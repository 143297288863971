import firebase from 'firebase';
import { Issue } from 'vacctrack';
import { PAGE_SIZE } from '../util/constants';
import FirestoreService from './FirestoreService';

export default class IssuesService extends FirestoreService<Issue> {
  static instance = new IssuesService();

  constructor() {
    super('issues');
  }

  async list(startAfter?: firebase.firestore.DocumentSnapshot) {
    let query = this.collection().orderBy('created', 'desc').limit(PAGE_SIZE);

    if (startAfter) {
      query = query.startAfter(startAfter);
    }

    return query.get();
  }
}
