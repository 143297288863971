import { Button, Card, FormGroup, FormLabel, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  title?: React.ReactNode;
  submitText?: React.ReactNode;
  onSubmit: () => void;
  onCancel?: () => void;
  loading?: boolean;
  row?: boolean;
}

const useStyles = makeStyles<Theme, { row?: boolean }>((theme) => ({
  card: {
    padding: theme.spacing(2),
  },
  cardColumn: {
    maxWidth: theme.breakpoints.values['sm'],
  },
  cardRow: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1, 1, 1, 2),
      alignItems: 'center',
    },
  },
  formGroup: {
    '& > * + *': {
      marginTop: theme.spacing(1.5),
    },
  },
  formGroupRow: {
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      '& > * + *': {
        marginTop: 0,
        marginLeft: theme.spacing(2),
      },
    },
  },
  formLabel: {
    [theme.breakpoints.up('sm')]: {
      marginRight: 'auto',
    },
  },
}));

const VTForm: React.FC<Props> = ({
  title,
  submitText,
  onSubmit,
  onCancel,
  children,
  loading,
  row,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ row });
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));

  const _onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form action="" onSubmit={_onSubmit}>
      <Card
        className={clsx(
          classes.card,
          row ? classes.cardRow : classes.cardColumn,
        )}
        variant="outlined"
      >
        <FormGroup
          classes={{ root: classes.formGroup, row: classes.formGroupRow }}
          row={row && isSm}
        >
          {title && (
            <FormLabel className={classes.formLabel}>{title}</FormLabel>
          )}
          {children}
          <Button type="submit" variant="outlined" disabled={loading}>
            {submitText ?? t('action.submit')}
          </Button>
          {onCancel && (
            <Button
              type="button"
              color="inherit"
              disabled={loading}
              onClick={onCancel}
            >
              {t('action.cancel')}
            </Button>
          )}
        </FormGroup>
      </Card>
    </form>
  );
};

export default VTForm;
