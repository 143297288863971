import { makeStyles } from '@material-ui/core/styles';
import { DataGrid, DataGridProps } from '@material-ui/data-grid';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const VTDataGrid = (props: DataGridProps) => {
  const classes = useStyles();

  return (
    <DataGrid
      className={classes.root}
      autoHeight
      rowsPerPageOptions={[]}
      disableSelectionOnClick
      {...props}
    />
  );
};

export default VTDataGrid;
