import { Box, Button } from '@material-ui/core';
import { ColDef, RowModel } from '@material-ui/data-grid';
import firebase from 'firebase';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Broadcast } from 'vacctrack';
import useData from '../../hooks/useData';
import BroadcastsService from '../../services/BroadcastsService';
import { Id } from '../../services/FirestoreService';
import { DEFAULT_COL_DEF } from '../../util/constants';
import i18n from '../../util/i18n';
import VTDataGrid from '../ui/VTDataGrid';
import CreateForm from './CreateForm';

const Broadcasts = () => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);

  const cb = useCallback(
    async (startAfter?: firebase.firestore.DocumentSnapshot) =>
      (await BroadcastsService.instance.list(startAfter)).docs,
    [],
  );

  const { data, loading, reload, ...rest } = useData(cb);

  const columns: ColDef[] = [
    {
      ...DEFAULT_COL_DEF,
      field: 'title',
      headerName: t('shared.title'),
      width: 320,
    },
    {
      ...DEFAULT_COL_DEF,
      field: 'created',
      headerName: t('shared.created'),
    },
  ];

  const broadcasts = data.map((sn) => ({ id: sn.id, ...sn.data() }));

  const rows = broadcasts.map((b) => ({
    id: b.id,
    title: b.title,
    created: i18n.dateTimeFormat(b.created),
  }));

  const onSelect = async (r: RowModel) => {
    if (!window.confirm(t('broadcasts.deleteConfirm'))) {
      return;
    }

    await BroadcastsService.instance.doc((r as Broadcast & Id).id).delete();

    reload();
  };

  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        {showForm ? (
          <CreateForm
            onSuccess={() => {
              reload();
              setShowForm(false);
            }}
          />
        ) : (
          <Button variant="outlined" onClick={() => setShowForm(true)}>
            {t('broadcasts.create')}…
          </Button>
        )}
      </Box>
      <VTDataGrid
        rows={rows}
        loading={loading}
        columns={columns}
        onRowClick={(r) => onSelect(r.row)}
        {...rest}
      />
    </>
  );
};

export default Broadcasts;
