import firebase from 'firebase/app';
import { Certificate, Reminder, Report, User } from 'vacctrack';
import { PAGE_SIZE } from '../util/constants';
import FirestoreService, { Id, typeConverter, unwrapQuerySnapshot } from './FirestoreService';

export interface PatientSearchQuery {
  email: string;
  firstName: string;
  lastName: string;
  certificateCount: string;
}

export default class UsersService extends FirestoreService<User> {
  static instance = new UsersService();

  constructor() {
    super('users');
  }

  listMsps(startAfter?: firebase.firestore.DocumentSnapshot) {
    let query = this.collection()
      .where('msp.verifiedTimestamp', '>', 0)
      .orderBy('msp.verifiedTimestamp', 'desc')
      .limit(PAGE_SIZE);

    if (startAfter) {
      query = query.startAfter(startAfter);
    }

    return query.get();
  }

  listPatients(startAfter?: firebase.firestore.DocumentSnapshot) {
    let query = this.collection()
      .where('type', '==', 'patient')
      .orderBy('created', 'desc')
      .limit(PAGE_SIZE);

    if (startAfter) {
      query = query.startAfter(startAfter);
    }

    return query.get();
  }

  async getBySearchQuery(
    {
      email,
      firstName,
      lastName,
      certificateCount: certificateCountStr,
    }: PatientSearchQuery,
    startAfter?: firebase.firestore.DocumentSnapshot,
  ) {
    let query = this.collection() as firebase.firestore.Query<User & Id>;
    const certificateCount = parseInt(certificateCountStr || '0', 10);

    if (certificateCount) {
      query = query.where('certificateCount', '>=', certificateCount);
    } else {
      if (email) {
        query = query.where('email', '==', email);
      }

      if (firstName) {
        query = query.where('patient.firstName', '==', firstName);
      }

      if (lastName) {
        query = query.where('patient.lastName', '==', lastName);
      }
    }

    if (startAfter) {
      query = query.startAfter(startAfter);
    }

    return query.get();
  }

  async getUserServiceItems(userId: string) {
    const userDoc = this.doc(userId);

    const [certificatesSn, reportsSn, remindersSn] = await Promise.all([
      userDoc
        .collection('certificates')
        .withConverter(typeConverter<Certificate>())
        .get(),
      userDoc
        .collection('reports')
        .withConverter(typeConverter<Report>())
        .get(),
      userDoc
        .collection('reminders')
        .withConverter(typeConverter<Reminder>())
        .get(),
    ]);

    return {
      certificates: unwrapQuerySnapshot(certificatesSn),
      reports: unwrapQuerySnapshot(reportsSn),
      reminders: unwrapQuerySnapshot(remindersSn),
    };
  }
}
