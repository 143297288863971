import {
  Box,
  Button,
  Card,
  Container,
  TextField,
  Typography,
} from '@material-ui/core';
import firebase from 'firebase/app';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    firebase.auth().signInWithEmailAndPassword(email, password);
  };

  return (
    <Container>
      <Box component={Card} sx={{ padding: 2, margin: 2 }}>
        <Typography variant="h6" component="h1">
          {t('auth.login')}
        </Typography>
        <form action="" onSubmit={onSubmit}>
          <TextField
            type="email"
            variant="outlined"
            margin="normal"
            label={t('auth.email')}
            value={email}
            required
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <br />
          <TextField
            type="password"
            variant="outlined"
            margin="normal"
            label={t('auth.password')}
            value={password}
            required
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
          <br />
          <br />
          <Button variant="contained" type="submit">
            {t('auth.signIn')}
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default Login;
