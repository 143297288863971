import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../locales/en/default.json';

function setup() {
  i18next.use(initReactI18next).init({
    ns: 'default',
    defaultNS: 'default',
    fallbackLng: 'en',
    supportedLngs: ['en'],
    load: 'languageOnly',
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        default: en,
      },
    },
  });
}

const i18n = {
  setup,
  dateTimeFormat: (
    date?: Date | number,
    options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  ) => Intl.DateTimeFormat('en-US', options).format(date),
};

export default i18n;
