import { Dialog, DialogContent, Typography } from '@material-ui/core';
import { ColDef, RowModel } from '@material-ui/data-grid';
import firebase from 'firebase';
import { merge } from 'lodash';
import omit from 'lodash/omit';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactJson from 'react-json-view';
import { User } from 'vacctrack';
import useData from '../../hooks/useData';
import { Id } from '../../services/FirestoreService';
import UsersService, { PatientSearchQuery } from '../../services/UsersService';
import { DEFAULT_COL_DEF } from '../../util/constants';
import i18n from '../../util/i18n';
import VTDataGrid from '../ui/VTDataGrid';
import PatientSearchForm from './PatientsSearchForm';

const Patients = () => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<PatientSearchQuery>();

  const cb = useCallback(
    async (startAfter?: firebase.firestore.DocumentSnapshot) =>
      searchQuery
        ? (await UsersService.instance.getBySearchQuery(searchQuery)).docs
        : (await UsersService.instance.listPatients(startAfter)).docs,
    [searchQuery],
  );

  const { data, loading, reload, ...rest } = useData(cb);

  const [selectedPatient, setSelectedPatient] = useState<
    Record<string, unknown> & { user: User & Id }
  >();

  const columns: ColDef[] = [
    {
      ...DEFAULT_COL_DEF,
      field: 'email',
      headerName: t('auth.email'),
      width: 300,
    },
    {
      ...DEFAULT_COL_DEF,
      field: 'name',
      headerName: t('shared.name'),
      width: 300,
    },
    {
      ...DEFAULT_COL_DEF,
      field: 'created',
      headerName: t('patient.created'),
    },
    {
      ...DEFAULT_COL_DEF,
      field: 'certs',
      headerName: t('patient.certificateCount'),
    },
  ];

  const users = data.map((sn) => sn.data()!);

  const rows = users.map((u) => ({
    id: u.id,
    email: u.email,
    name: [u.patient?.firstName, u.patient?.lastName].filter(Boolean).join(' '),
    created: i18n.dateTimeFormat(u.created),
    certs: u.certificateCount,
  }));

  const onSearch = async (query: PatientSearchQuery) => {
    const certificateCount = parseInt(query.certificateCount || '0', 10);
    console.log(certificateCount)
    if (
      !query.email &&
      !query.firstName &&
      !query.lastName &&
      !certificateCount
    ) {
      setSearchQuery(undefined);
      return;
    }

    setSearchQuery(query);
  };

  const onEdit = (updatedSrc: User & Id) => {
    return UsersService.instance.doc(updatedSrc.id).set(
      {
        patient: updatedSrc.patient,
      },
      { merge: true },
    );
  };

  const onRowClick = async (r: RowModel) => {
    const user = users.find((d) => d.id === r.id);

    if (!user) {
      return;
    }

    const {
      certificates,
      reports,
      reminders,
    } = await UsersService.instance.getUserServiceItems(user!.id);

    setSelectedPatient({
      certificates: certificates.map((c) =>
        merge(
          {
            ...omit(c, ['id', 'releases', 'patient', 'userId']),
            created: i18n.dateTimeFormat(c.created),
            serviceDate: i18n.dateTimeFormat(c.serviceDate),
          },
          {
            product: {
              expiryDate: i18n.dateTimeFormat(c.product.expiryDate, {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }),
            },
          },
        ),
      ),
      selfReports: reports.map((c) => ({
        ...omit(c, ['id', 'userId']),
        created: i18n.dateTimeFormat(c.created),
        serviceDate: i18n.dateTimeFormat(c.serviceDate),
      })),
      reminders: reminders.map((c) => ({
        ...omit(c, ['id', 'userId', 'subject']),
        created: i18n.dateTimeFormat(c.created),
        reminderDate: i18n.dateTimeFormat(c.reminderDate),
      })),
      user,
    });
  };

  return (
    <>
      <PatientSearchForm {...{ onSearch, loading }} />
      <VTDataGrid
        rows={rows}
        columns={columns}
        onCellClick={(u) => onRowClick(u.row)}
        {...rest}
      />
      <Dialog
        onClose={() => setSelectedPatient(undefined)}
        open={!!selectedPatient}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          {selectedPatient &&
            (() => {
              const { user, ...rest } = selectedPatient;
              return (
                <>
                  <ReactJson
                    src={rest}
                    name={false}
                    collapsed={2}
                    displayDataTypes={false}
                    quotesOnKeys={false}
                    enableClipboard={false}
                    // @ts-ignore
                    displayArrayKey={false}
                    sortKeys
                  />
                  <br />
                  <Typography variant="body2">
                    {t('patient.editInfo')}
                  </Typography>
                  <ReactJson
                    src={user}
                    name={false}
                    displayDataTypes={false}
                    quotesOnKeys={false}
                    enableClipboard={false}
                    // @ts-ignore
                    displayArrayKey={false}
                    sortKeys
                    onEdit={(pr) => {
                      if (pr.namespace[0] !== 'patient') {
                        return false;
                      }

                      onEdit(pr.updated_src as User & Id);

                      return true;
                    }}
                  />
                </>
              );
            })()}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Patients;
