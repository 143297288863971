import { Dialog, DialogContent } from '@material-ui/core';
import { ColDef, RowModel } from '@material-ui/data-grid';
import firebase from 'firebase';
import stableStringify from 'json-stable-stringify';
import { Dictionary } from 'lodash';
import keyBy from 'lodash/keyBy';
import pick from 'lodash/pick';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Issue, Product } from 'vacctrack';
import useData from '../../hooks/useData';
import { Id } from '../../services/FirestoreService';
import IssuesService from '../../services/IssuesService';
import ProductsService from '../../services/ProductsService';
import { DEFAULT_COL_DEF } from '../../util/constants';
import i18n from '../../util/i18n';
import VTDataGrid from '../ui/VTDataGrid';

const VaccWatch = () => {
  const { t } = useTranslation();

  const cb = useCallback(
    async (startAfter?: firebase.firestore.DocumentSnapshot) =>
      (await IssuesService.instance.list(startAfter)).docs,
    [],
  );

  const { data, loading, reload, ...rest } = useData(cb);

  const [productsById, setProductsById] = useState<Dictionary<Product & Id>>();
  const [selectedIssue, setSelectedIssue] = useState<Issue & Id>();

  useEffect(() => {
    (async () => {
      setProductsById(
        keyBy(
          (await ProductsService.instance.list()).docs.map((d) => d.data()!),
          'id',
        ),
      );
    })();
  }, []);

  const columns: ColDef[] = [
    {
      ...DEFAULT_COL_DEF,
      field: 'created',
      headerName: t('shared.created'),
    },
    {
      ...DEFAULT_COL_DEF,
      field: 'diseaseName',
      headerName: t('products.name'),
    },
    {
      ...DEFAULT_COL_DEF,
      field: 'product',
      headerName: t('products.brand'),
      width: 320,
    },
    {
      ...DEFAULT_COL_DEF,
      field: 'effects',
      headerName: t('vaccwatch.effects'),
      width: 320,
    },
  ];

  const issues = data.map((d) => d.data()!);

  const rows = productsById
    ? issues.map((is) => {
        const product = productsById[is.productId];

        return {
          ...pick(is, [
            'ageGroup',
            'bloodType',
            'comment',
            'ethnicity',
            'preexistingConditions',
            'productExpiryDate',
            'productLotNumber',
            'race',
            'shotNumber',
            'source',
            'id',
          ]),
          created: i18n.dateTimeFormat(is.created),
          serviceDate: i18n.dateTimeFormat(is.serviceDate),
          diseaseName: product.name,
          product: `${product.brand} – ${product.manufacturer}`,
          effects: is.effects.join(', ').replace(/-/g, ' '),
          effectsOther: is.otherEffect,
        };
      })
    : [];

  const onSelect = (r: RowModel) => setSelectedIssue(r as Issue & Id);

  return (
    <>
      <VTDataGrid
        rows={rows}
        loading={loading}
        columns={columns}
        onRowClick={(r) => {
          onSelect(r.row);
        }}
        {...rest}
      />
      <Dialog
        onClose={() => setSelectedIssue(undefined)}
        open={!!selectedIssue}
      >
        <DialogContent>
          {selectedIssue && (
            <pre>
              {stableStringify(selectedIssue, {
                space: 2,
              })}
            </pre>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VaccWatch;
