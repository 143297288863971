import { DataGridProps } from '@material-ui/data-grid';
import firebase from 'firebase';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PAGE_SIZE } from '../util/constants';

export default function useData<T>(
  callback: (
    startAfter?: firebase.firestore.DocumentSnapshot,
  ) => Promise<firebase.firestore.DocumentSnapshot<T>[]>,
) {
  const [data, setData] = useState<firebase.firestore.DocumentSnapshot<T>[]>();

  const [loading, setLoading] = useState(true);

  const [rowCount, setRowCount] = useState(Infinity);
  const [page, setPage] = useState(1);

  // reset on callback change
  useEffect(() => {
    setData([]);
    setPage(1);
    setRowCount(Infinity);
  }, [callback]);

  const loadData = useCallback(
    async (startAfter?: firebase.firestore.DocumentSnapshot) => {
      setLoading(true);

      const ret = await callback(startAfter);

      setData((oldData) => {
        const newData = startAfter ? [...(oldData ?? []), ...ret] : ret;

        if (ret.length < PAGE_SIZE) {
          setRowCount(newData.length);
        } else {
          setRowCount(Infinity);
        }

        return newData;
      });

      setLoading(false);
    },
    [callback],
  );

  useEffect(() => {
    if (data && page * PAGE_SIZE > data.length && rowCount === Infinity) {
      loadData(data[data.length - 1]);
    }
  }, [data, loadData, page, rowCount]);

  return useMemo(
    (): Omit<DataGridProps, 'columns' | 'rows'> & {
      data: firebase.firestore.DocumentSnapshot<T>[];
      reload: () => void;
    } => ({
      data: data ?? [],
      loading,
      page,
      onPageChange({ page }: { page: number }) {
        setPage(page);
      },
      reload: () => loadData(),
      rowCount,
      pageSize: PAGE_SIZE,
    }),
    [data, loadData, loading, page, rowCount],
  );
}
