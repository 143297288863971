import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import i18n from './util/i18n';

firebase.initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));

if (process.env.REACT_APP_FUNCTIONS_EMULATOR_URL) {
  const [host, port] = process.env.REACT_APP_FUNCTIONS_EMULATOR_URL.split(':');
  firebase.functions().useEmulator(host, parseInt(port, 10));
}

i18n.setup();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
