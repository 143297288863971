import { Box, Button } from '@material-ui/core';
import { ColDef, RowModel } from '@material-ui/data-grid';
import firebase from 'firebase';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useData from '../../hooks/useData';
import UsersService from '../../services/UsersService';
import { DEFAULT_COL_DEF } from '../../util/constants';
import i18n from '../../util/i18n';
import VTDataGrid from '../ui/VTDataGrid';
import ManualVerifyForm from './ManualVerifyForm';

const Msps = () => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);

  const cb = useCallback(
    async (startAfter?: firebase.firestore.DocumentSnapshot) =>
      (await UsersService.instance.listMsps(startAfter)).docs,
    [],
  );

  const { data, loading, reload, ...rest } = useData(cb);

  const columns: ColDef[] = [
    {
      ...DEFAULT_COL_DEF,
      field: 'email',
      headerName: t('auth.email'),
      width: 220,
    },
    {
      ...DEFAULT_COL_DEF,
      field: 'name',
      headerName: t('shared.name'),
      width: 320,
    },
    {
      ...DEFAULT_COL_DEF,
      field: 'npi',
      headerName: t('msps.npi'),
      width: 120,
    },
    {
      ...DEFAULT_COL_DEF,
      field: 'verified',
      headerName: t('msps.verifiedOn'),
      width: 190,
    },
  ];

  const msps = data.map((sn) => sn.data()!);

  const rows = msps.map((u) => ({
    id: u.id,
    email: u.email,
    name: u.msp?.npiInfo.basic.name,
    npi: u.msp?.npiInfo.number,
    verified: i18n.dateTimeFormat(u.msp?.verifiedTimestamp),
  }));

  const onSelect = (r: RowModel) => {
    console.log(r);
  };

  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        {showForm ? (
          <ManualVerifyForm onSuccess={() => setShowForm(false)} />
        ) : (
          <Button variant="outlined" onClick={() => setShowForm(true)}>
            {t('msps.confirmManually')}
          </Button>
        )}
      </Box>
      <VTDataGrid
        rows={rows}
        loading={loading}
        columns={columns}
        onRowClick={(r) => onSelect(r.row)}
        {...rest}
      />
    </>
  );
};

export default Msps;
