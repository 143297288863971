import firebase from 'firebase';
import { Product } from 'vacctrack';
import { PAGE_SIZE } from '../util/constants';
import FirestoreService from './FirestoreService';

export default class ProductsService extends FirestoreService<Product> {
  static instance = new ProductsService();
  constructor() {
    super('products');
  }

  create(data: Product) {
    return this.collection().add({
      id: '',
      ...data,
    });
  }

  list(startAfter?: firebase.firestore.DocumentSnapshot) {
    let query = this.collection().orderBy('name').limit(PAGE_SIZE);

    if (startAfter) {
      query = query.startAfter(startAfter);
    }

    return query.get();
  }
}
