import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CertificatesQuery } from '../../services/CertificatesService';
import VTForm from '../ui/VTForm';

interface Props {
  onSearch: (query: CertificatesQuery) => void;
  loading?: boolean;
}

const CertificatesFilterForm = ({ onSearch, loading }: Props) => {
  const { t } = useTranslation();
  const [createdFrom, setCreatedFrom] = useState('');
  const [createdTo, setCreatedTo] = useState('');

  return (
    <VTForm
      onSubmit={() => onSearch({ createdFrom, createdTo })}
      submitText={t('shared.search')}
      loading={loading}
      row
    >
      <TextField
        type="date"
        label={t('shared.createdFrom')}
        value={createdFrom}
        onChange={(e) => setCreatedFrom(e.currentTarget.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        type="date"
        label={t('shared.createdTo')}
        value={createdTo}
        onChange={(e) => setCreatedTo(e.currentTarget.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </VTForm>
  );
};

export default CertificatesFilterForm;
