import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    height: `calc(100vh - ${theme.spacing(7)}px)`,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
      height: `calc(100vh - ${theme.spacing(8)}px)`,
    },
  },
}));

const VTContainer: React.FC = (props) => {
  const classes = useStyles();

  return <Container className={classes.root}>{props.children}</Container>;
};

export default VTContainer;
